import React from "react";
import { graphql } from "gatsby";

import TermlyPage from "~/components/TermlyPage";
import { TERMLY_PAGE_TYPES } from "components/TermlyPage";

const Disclaimer = ({ data, location }) => {
  return <TermlyPage data={data} location={location} pageType={TERMLY_PAGE_TYPES.DISCLAIMER} />;
};

export const query = graphql`
  {
    contentfulPage(slug: { eq: "disclaimer" }) {
      title
      termlyScriptUrl
      termlyId
    }
  }
`;

export default Disclaimer;
